.timeline {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  @include mq(md) {
    padding: 0 100px;
  }

  &__item {
    display: flex;
    padding: 0;

    &:before {
      display: none;
    }

    &:first-child {
      .timeline {
        &__meta {
          &:before {
            top: 50%;
            height: 50%;
          }
        }
      }
    }
    &:last-child {
      .timeline {
        &__meta {
          &:before {
            top: 0;
            height: 50%;
          }
        }
      }
    }
  }

  &__meta {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //flex: 0 1 40%;
    padding: 20px 65px 20px 0;
    font-size: 14px;
    font-weight: 600;
    color: rgba(255,255,255,.6);
    text-align: right;
    //min-width: 40%;
    @include mq(md) {
      min-width: auto;
    }

    &:before {
      content: ' ';
      position: absolute;
      right: 40px;
      top: 0;
      width: 2px;
      background: var(--btn-secundary-bg);
      height: 100%;
    }

    &:after {
      content: ' ';
      position: absolute;
      right: 29px;
      top: 50%;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 5px solid var(--btn-secundary-bg);
      background: var(--color-primary);
      transform: translateY(-50%);
    }

    &--milestone {
      &:after {
        content: ' ';
        right: 33px;
        width: 16px;
        height: 16px;
        border-radius: 0;
        border: 4px solid #ccc;
        background: #fff;
        transform: translateY(-50%) rotate(45deg);
      }
    }

    &--title {
      .timeline__meta-title {
        font-size: inherit;
      }
    }

    &--bold {
      font-size: 26px;

      &:after {
        content: ' ';
        position: absolute;
        right: 24px;
        top: 50%;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        border: 6px solid var(--btn-secundary-bg);
        background: var(--color-primary);
        transform: translateY(-50%);
      }
    }
  }

  &__date {

  }

  &__location {
    font-style: italic;
  }

  &__content {
    position: relative;
    flex: 1;
    margin: 20px 0;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -14px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 14px 14px 14px 0;
      border-color: transparent var(--btn-secundary-bg) transparent transparent;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -12px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12px 12px 12px 0;
      border-color: transparent var(--color-bg-lighter) transparent transparent;
    }

    &--bold {
      background: none;
      padding: 0;
      border: none;

      &:before,
      &:after {
        display:none;
      }
    }
  }

  &__label {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 10px;
    color: rgba(255,255,255,.5);
    letter-spacing: .1em;
  }

  &__headline {
    margin: 0;
    font-size: 20px;
  }

  &__text {
    font-size: 14px;
    margin-top: 20px;
  }

  &__footer {

  }

}

.badge {
  padding: 5px 8px;
  margin-right: 10px;
  background-color: var(--color-accent);
  color: rgba(255,255,255,.6);
}
