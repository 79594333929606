.topbar {
  height: 40px;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  border-bottom: 1px solid var(--color-border-light);
  color: var(--color-text);
  font-size: var(--text-xs);
  font-weight: var(--type-weight-meta);

  &__title {
    margin-left: 20px;
  }

  &__navbar {
    display: flex;
    align-items: stretch;
    height: 100%;

    ul {
      &:not(:last-child){
        margin-right: 40px;
      }
    }
  }
}
