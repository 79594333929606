.as-search {
  position: absolute;
  z-index: var(--zindex-search);
  top: 100%;
  left: 0;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
  transform: scaleY(0);
  transform-origin: top;
  will-change: transform;
  background: transparent;
  box-shadow: inset 0 4px 10px -9px rgba(0,0,0,.6);

  &__content {
    display: flex;
    opacity: 0;
    will-change: opacity;
    padding: var(--space-md);
  }

  &__input {
    border: none;
    border-radius: 100px;
    font-size: var(--text-sm);
    text-align: center;
    flex: 1 1 auto;
    margin-right: var(--space-sm);
    min-width: 0;
  }

  &__button {
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
  }

  &.is {
    &--visible {
      visibility: visible;
      transform: none;
      pointer-events: all;
      transition: transform .25s ease;

      .as-search {
        &__content {
          opacity: 1;
          transition: opacity .25s ease;
          transition-delay: .15s;
        }
      }
    }
  }
}
