/*
SET CSS VARS
*/

:root {
  /*
  DEFAULT
  */
  /* header height */
  --header-height: 60px;
  /* mobile nav */
  --mobile-nav-width: 400px;
  /*
  COLORS
  */
  /* main colors */
  --color-primary: #F79B11;
  --color-primary-light: #F79B11;
  --color-primary-dark: #F79B11;
  --color-primary-bg: rgba(247,155,17,0.5);
  --color-accent: #00beff; //#00e5ff;
  --color-accent-light:#9af5ff;
  --color-accent-dark: #0089C3;
  --color-accent-bg: rgba(0,157,224,0.5);
  --color-bg: #03202d;
  --color-bg-light: #1c3642;
  --color-bg-lighter: #102b38;
  --color-bg-dark: #021d29;
  --color-bg-darker: #021d29;
  /* sahdes */
  --black: #1d1d21;
  --gray-10: #242429;
  --gray-9: #3c3c44;
  --gray-8: #53535f;
  --gray-7: #6b6b7a;
  --gray-6: #858594;
  --gray-5: #a5a5a6;
  --gray-4: #b8b8b8;
  --gray-3: #a0a0ac;
  --gray-2: #d1d0d2;
  --gray-1: #f0efed;
  --white: white;
  /* feedback */
  --color-info: #518aff;
  --color-valid: #78e87d;
  --color-error: #f35050;
  --color-warning: #f7b13a;
  /* typography */
  --color-text: rgba(255,255,255,.9);
  --color-text-inverse: rgba(0,0,0,.8);
  --color-text-heading: var(--color-primary);
  --color-text-subtle: rgba(255,255,255,.6);
  --color-text-meta: rgba(255,255,255,.6);
  --color-link: var(--color-accent);
  --color-link-visited: var(--color-accent-light);
  --color-mark: var(--color-accent-bg);
  /* border */
  --color-border: #365761;
  --color-border-light: #365761;
  --color-border-dark: #365761;
  /* forms */
  --form-element-border: var(--color-border);
  --form-element-border-focus: var(--color-accent);
  --form-element-border-error: var(--color-error);
  --form-element-bg: #09323F;
  --form-text-placeholder: #fff;
  /* buttons primary */
  --btn-primary-bg: #fff;
  --btn-primary-hover: var(--color-accent);
  --btn-primary-active: var(--color-accent-dark);
  --btn-primary-label: rgba(0,0,0,.8);
  --btn-primary-inverse-active: red;
  /* buttons secundary */
  --btn-secundary-bg: #09323F;
  --btn-secundary-hover: var(--color-accent);
  --btn-secundary-active: var(--color-accent-dark);
  --btn-secundary-label: var(--white);
  --btn-secundary-inverse-active: red;
  /* icons */
  --color-icon-primary: var(--color-primary);
  --color-icon-meta: rgba(255,255,255,.35);

  /*
  SPACING
  */
  /* spacing values */
  --space-unit: 1em;
  --space-xxs: calc(0.25 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));

  /*
  LAYERS
  */
  --zindex-drawer: 900;
  --zindex-header: 1000;
  --zindex-search: 900;
  --zindex-components: 2000;
  --zindex-backdrop: 800;

  /*
  CONTAINER
  */
  /* Forms */
  --container-xs-max-widths: 540px;
  /* Flow Text */
  --container-sm-max-widths: 820px;
  /* Flow Text */
  --container-md-max-widths: 1140px;

  /*
  TYPOGRAPHY
  */
  /* text */
  --font-primary: 'Open Sans', sans-serif;
  /* headlines */
  --font-secondary: 'Roboto Condensed', sans-serif;
  /* icomoon icons */
  --font-icon: 'icons';
  /* set base values */
  --text-base-size: 0.938em;
  /* This is for smaller text (sm and down) */
  --text-scale-ratio-down: 1.125;
  /* This is for larger text (md and up) */
  --text-scale-ratio-up: 1.12;
  /* line-height */
  --heading-line-height: 1.3;
  --body-line-height: 1.5;
  /* type scale */
  /*
  NOTE:
  Note that in defining each text size variable we multiply 1em by
  the {--text-scale-ratio-down/--text-scale-ratio-up}. That 1em is NOT the --text-base-size value.
  You could set a --text-base-size different from 1em (while you
  shouldn’t change the 1m in the calc() function).

  Since the Em unit is a relative unit equal to the current font size,
  if we update the --text-base-size variable at a specific media query,
  we update the font-size of the body, and, with a cascade effect,
  all the text size variables. The whole typography is affected.

  For more control we have made two variables out of --text-scale-ratio.
  --text-scale-ratio-down is for font sizes sm and smaller.
  --text-scale-ratio-up is for font sizes md and larger
  */
  --text-xxs: calc(((1em / var(--text-scale-ratio-down)) / var(--text-scale-ratio-down)) / var(--text-scale-ratio-down));
  --text-xs: calc(var(--text-xxs) * var(--text-scale-ratio-down));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio-down));
  --text-md: calc(1em * var(--text-scale-ratio-up));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio-up));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio-up));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio-up));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio-up));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio-up));
  /* type weight font-primary for flow text */
  --type-weight-base: 400;
  --type-weight-bold: 600;
  --type-weight-meta: var(--type-weight-bold);
  --type-weight-link: 400;
  --type-weight-button: 600;
  /* type weight font-secondary for headings */
  --type-weight-heading: 700;
  /* adjust typo-container */
  --component-body-line-height: calc(var(--body-line-height) * var(--line-height-multiplier));
  --component-heading-line-height: calc(var(--heading-line-height) * var(--line-height-multiplier));
  /*
  This variable adjusts the line-height in the .is--typo-container wrapper.
  */
  --line-height-multiplier: 1;
  /*
  This variable adjusts the distances.
  The defined margin-bottom can be increased or reduced.
  This applies e.g. for headlines, ol/ul, paragraph and the content-wrapper
  */
  --text-vspace-multiplier: 1;
}

/*
RESPONSIVE
*/
@include mq(lg){
  :root {
    /* header height */
    --header-height: 80px;
    /* adjust typo */
    --line-height-multiplier: 1.1;
    --text-vspace-multiplier: 1.25;
    /* set base values */
    --text-base-size: 1.125em;
    /* This is for smaller text (sm and down) */
    --text-scale-ratio-down: 1.18;
    /* This is for larger text (md and up) */
    --text-scale-ratio-up: 1.2;
    /* line-height */
    --heading-line-height: 1.25;
    --body-line-height: 1.58;
  }
}
