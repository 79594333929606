.content-block {

}

/*
The '.content-wrapper' class is not a <CONTAINER> because it can also be floated and many more. This is to be used for more dynamic elements.

Usually this element is used in the flow text.
For example floated pictures or quotes.
*/

.content-wrapper {
  margin-bottom: calc(var(--space-md) * var(--text-vspace-multiplier));
  /*
  The '.is--' classes are modifiers and change the basic properties.
  */
  &.is {
    /*
    If the container has a background color, the padding must be set up and down.
    Otherwise, the content has no space.
    */
    &--bg-color-1,
    &--bg-color-2,
    &--bg-color-3,
    &--bg-color-4,
    &--bg-color-5 {
      padding: var(--space-lg);
    }

    &--pos-left,
    &--pos-right {
      position: relative;
      z-index: 10;

      @include mq(lg) {
        // 1/3 of the content-wrapper
        max-width: calc(var(--container-sm-max-widths) / 3);

        &:after {
          content: "";
          clear: both;
          display: table;
        }
      }
    }

    &--pos-left {
      @include mq(lg) {
        float: left;
        margin-right: var(--space-lg);

        &:after {
          content: "";
          clear: both;
          display: table;
        }
      }
      @include mq(xl) {
        margin-left: -80px;
      }
    }

    &--pos-right {
      @include mq(lg) {
        float: right;
        margin-left: var(--space-lg);
      }
      @include mq(xl) {
        margin-right: -80px;
      }
    }
  }
}
