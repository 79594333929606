.pageloader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  transition:
    opacity .500s ease,
    transform .500s ease;
  will-change: opacity, transform;

  &:after {
    pointer-events: none;
    margin-left: -14.5px;
    content: "";
    width: 29px;
    height: 29px;
    border: 3px solid var(--color-accent);
    border-radius: 50%;
    animation: cssload-scale .8s infinite linear;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 7;
  }

  &.is {
    &--loading {
      opacity: 1;
    }
  }
}

/*
Element preloader
*/

.is {
  &--resolved {
    > * {
      transition:
        opacity .6s ease-in-out;
      opacity: 1;
    }
  }

  &--unresolved {
    position: relative;
    height: auto;
    overflow: hidden;

    &:after {
      pointer-events: none;
      margin-left: -14.5px;
      content: "";
      width: 29px;
      height: 29px;
      border: 3px solid var(--color-accent);
      border-radius: 50%;
      animation: cssload-scale .8s infinite linear;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 7;
    }

    > * {
      opacity: 0;
    }
  }
}

@keyframes cssload-scale {
  0% {
    transform: scale(0)
  }
  90% {
    transform: scale(.7)
  }
  100% {
    transform: scale(1)
  }
}
