
.rotate-center {
	animation: rotate-center 360s linear infinite;
}
.rotate-center-reverse {
	animation: rotate-center 360s linear infinite reverse;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
