.list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.is {
    &--list-default {
      .list {
        &__item {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          padding: var(--space-xs) var(--space-sm);
        }

        &__text {
          color: var(--color-text);
        }

        &__text-secundary {
          color: var(--color-text-meta);
          font-size: var(--text-sm);
          font-weight: var(--type-weight-base);
        }

        &__icon {
          display: inline-flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          margin-right: var(--space-unit);
          //color: var(--color-icon-meta);
        }

        &__meta {
          margin-left: auto;
          margin-right: 0;
          color: var(--color-icon-meta);
        }

        &__divider {
          height: 2px;
          background: var(--color-border);
          width: 100%;
        }
      }
    }

    &--hover {
      li {
        position: relative;

        &:before {
          content: '';
          pointer-events: none;
          position: absolute;
          border-radius: inherit;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          z-index: 0;
          border: 2px solid var(--color-accent);
          box-shadow: 0 3px 20px var(--color-accent);
          opacity: 0;
          transition: opacity .6s cubic-bezier(0.0, 0.0, 0.2, 1);
        }

        &:hover {
          background: rgba(255,255,255,.05);
          z-index: 1;

          &:before {
            opacity: 1;
          }
        }
      }
    }

    &--inline {
      display: flex;
      align-items: center;

      li {
        display: flex;
        align-items: center;
        height: 100%;

        &:not(:last-child) {
          margin-right: var(--space-lg);
        }
      }
    }
  }
}
