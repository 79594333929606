.is--scroll-disable {
  overflow: hidden!important;
}

// Block page scrolling
.no-scroll {
  overflow: hidden;
  // Scrollbar width - This prevents content jumping
  padding-right: 16px;
}

.overflow-hidden {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

// Backdrop
.as-backdrop {
  content: '';
  display: block;
  position: fixed;
  z-index: var(--zindex-backdrop);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  pointer-events: none;
  opacity: 0;
  transition: opacity .25s cubic-bezier(.4,0,.2,1);
  will-change: opacity;

  &.is {
    &--visible {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

// Width's
.w-100 {
  width: 100%;
}

// Modifiers
.is {
  &--sticky {
    position: sticky;
    top: 0;
    padding: 20px 0;
  }
  &--hidden {
    display: none;
  }
  &--relative {
    position: relative;
  }
  &--overflow-hidden {
    overflow: hidden;
  }
}
