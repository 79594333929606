
// TODO: Irgendwas stimmt mit den var's nicht. Sie kommen nicht an.
// https://www.smashingmagazine.com/2018/05/css-custom-properties-strategy-guide/

.is {
  /* Position */
  &--relative {
    position: relative !important;
  }
  /* Spacing */
  &--block {
    padding: var(--space-md) var(--space-lg) !important;
    @include mq(lg) {
      //padding: var(--space-lg) var(--space-xl) !important;
    }
  }

  /* Typography */
  &--text {
    &-weight {
      &-base {
        font-weight: var(--type-weight-base) !important;
      }
      &-bold {
        font-weight: var(--type-weight-bold) !important;
      }
      &-meta {
        font-weight: var(--type-weight-meta) !important;
      }
      &-link {
        font-weight: var(--type-weight-link) !important;
      }
      &-button {
        font-weight: var(--type-weight-button) !important;
      }
      &-heading {
        font-weight: var(--type-weight-heading) !important;
      }
    }
    &-size {
      &-xxl {
        font-size: var(--text-xxl) !important;
      }
      &-xl {
        font-size: var(--text-xl) !important;
      }
      &-lg {
        font-size: var(--text-lg) !important;
      }
      &-md {
        font-size: var(--text-md) !important;
      }
      &-sm {
        font-size: var(--text-sm) !important;
      }
      &-xs {
        font-size: var(--text-xs) !important;
      }
      &-xxs {
        font-size: var(--text-xxs) !important;
      }
      &-xxxs {
        font-size: var(--text-xxxs) !important;
      }
    }
  }
  /* Background */
  &--bg {
    &-body {
     background-color: var(--color-bg) !important;
    }
    &-body-light {
     background-color: var(--color-bg-light) !important;
    }
    &-body-lighter {
      background-color: var(--color-bg-lighter) !important;
    }
    &-body-gray-1 {
      background-color: var(--gray-1) !important;
    }
    &-body-dark {
      background-color: var(--color-bg-dark) !important;
    }
    &-body-darker {
      background-color: var(--color-bg-darker) !important;
    }
  }
  /* Color */
  &--color {
    &-primary {
      color: var(--color-primary) !important;
    }
    &-accent {
      color: var(--color-accent) !important;
    }
    &-body {
      color: var(--color-text) !important;
    }
    &-heading {
      color: var(--color-text-heading) !important;
    }
    &-subtle {
      color: var(--color-text-subtle) !important;
    }
    &-meta {
      color: var(--color-text-meta) !important;
    }
    &-link {
      color: var(--color-link) !important;
    }
    &-link-visited {
      color: var(--color-link-visited) !important;
    }
    &-mark {
      color: var(--color-mark) !important;
    }
    &-white {
      color: white !important;
    }
    &-transparent {
      color: rgba(255,255,255,.8) !important;
    }
    &-info {
      color: var(--color-info) !important ;
    }
    &-valid {
      color: var(--color-valid) !important;
    }
    &-error {
      color: var(--color-error) !important;
    }
    &-warning {
      color: var(--color-warning) !important;
    }
  }
  /* Icons */
  &--color-icon {
    &-primary {
      color: var(--color-icon-primary) !important;
    }
    &-meta {
      color: var(--color-text-meta) !important;
    }
  }
  /* Shapes */
  // TODO: Jan: Shapes in extra .scss? Hier ist es falsch platziert, da zu viele Styles
  &--shape {
    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: var(--space-lg);
      min-height: var(--space-lg);
      border-radius: 50%;
      padding: .5em;
    }
    &-rectangle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      padding: .25em .5em;
    }
  }
  /* Shadows */
  &--box-shadow {
    box-shadow: 0 3px 50px -20px rgba(0,0,0,.15)!important;
  }
  &--shadow {
    &-1 {
      box-shadow: 0 0 100px 0 rgba(0,0,0,.15) !important;
    }
  }
  /* Gradient */
  &--inner-gradient {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      z-index: 2;
      pointer-events: none;
      width: 40px;
      height: 100%;
    }

    &:before {
      left: 0;
      background: linear-gradient(to right, var(--color-bg-light) 0%, rgba(0,0,0,0) 100%);
    }

    &:after {
      right: 0;
      background: linear-gradient(to right, rgba(0,0,0,0) 0%, var(--color-bg-light) 100%);
    }

    @include mq(md) {
      &:before,
      &:after {
        display: none;
      }
    }
  }
  /* Spacing */
  &--hero-padding {
    padding: 0 0 50vw;
    @include mq(md) {
      padding: 80px 0;
    }
  }
  /* Border */
  &--border {
    border: 2px solid var(--color-border) !important;

    &-top {
      border-top: 2px solid var(--color-border) !important;
    }
    &-right {
      border-right: 2px solid var(--color-border) !important;
    }
    &-bottom {
      border-bottom: 2px solid var(--color-border) !important;
    }
    &-left {
      border-left: 2px solid var(--color-border) !important;
    }
  }
  &--border-bold {
    border-width: 3px !important;
  }

  &--border-glow {
    position: relative;
    border-radius: 5px;

    &:before {
      content: "";
      pointer-events: none;
      position: absolute;
      border-radius: inherit;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      z-index: 0;
      border: 2px solid var(--color-accent);
      box-shadow: 0 3px 20px var(--color-accent);
      opacity: 0;
      transition: opacity .6s cubic-bezier(0,0,.2,1);
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
}
