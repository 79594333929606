.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  border-collapse: collapse;

  thead {
    background-color: rgba(255,255,255,.05);
    font-weight: var(--type-weight-bold);

    td,
    th {
      // Header table
      border-width: 2px;
      color: var(--color-text-subtle);
    }
  }

  tfoot {
    font-weight: var(--type-weight-bold);

    td,
    th {
      border-width: 1px;
      color: green;
    }
  }

  tbody {
    background-color: transparent;

    tr {
      &:last-child {
        td,
        th {
          // Bottom table
          border-bottom-width: 2px;
        }
      }
    }
  }

  th {
    color: var(--color-text-meta);
    text-align: left;
  }

  td,
  th {
    border: 1px solid var(--color-border);
    border-width: 1px;
    padding: var(--space-sm);
    vertical-align: top;
  }

  /* Modifiers */
  &.is {
    &--bordered {
      td,
      th {
        border-width: 1px;

        tr {
          &:last-child {
            td,
            th {
              border-bottom-width: 1px ;
            }
          }
        }
      }
    }
  }
}
