/*
Homepage: Background Grid
*/

:root {
  --grid-color: rgba(0,190,255, 0.2);
  --grid-height:140vh;
  --grid-line-x: 42px;
  --grid-line-y: 34px;
  --grid-rotate: 50deg;
  --grid-line-y-width: 2px;
  --grid-line-x-width: 1px;
}
@include mq(md){
  :root {
    --grid-height: 80vh;
    --grid-line-x: 90px;
    --grid-line-y: 90px;
  }
}

.grid-outer {
  position: relative;

  &.is {
    &--isometric {
      .grid {
        perspective: none;

        &__lines,
        &__dots {
          width: 200%;
          height: 200%;
          transform: rotateX(60deg) rotateY(0deg) rotateZ(-45deg) translate(-50%, -50%);
          transform-origin: 150% 0;
          @include mq(md){
            transform-origin: 50% 0%;
          }
        }
      }
    }
  }

  // Shadow Bottom
  &:before {
    content:'';
    width: 100%;
    height: 70%;
    left: 0;
    bottom: -5px; // hide visible bottom line
    position: absolute;
    z-index: -1;
    background: linear-gradient(to top, var(--color-bg) 20%,rgba(0,0,0,0) 100%);
  }

  // Shadow Top
  &:after {
    content:'';
    width: 200%;
    height: 80%;
    left: -50%;
    top: -5px; // hide visible top line
    position: absolute;
    z-index: -1;
    background:
      linear-gradient(to bottom, var(--color-bg) 0%,rgba(0,0,0,0) 10%);

  }
}

.grid {
  position: relative;
  z-index: -5;
	width: 100vw;
	height: var(--grid-height);
	overflow: hidden;
	perspective: calc(var(--grid-height) * .75);

  &__lines {
    width: 100%;
  	height: 200%;
  	background-image:
  		linear-gradient(to right, var(--grid-color) var(--grid-line-y-width), transparent 0),
  		linear-gradient(to bottom, var(--grid-color) var(--grid-line-x-width), transparent 0);
      background-position: center bottom;
  	background-size: var(--grid-line-x) var(--grid-line-y);
  	background-repeat: repeat;
  	transform-origin: 100% 0 0;
    transform: rotateX(var(--grid-rotate));
  	//animation: play 120s linear infinite;
  }

  &__dots {
    top: 0;
    position: absolute;
    width: 100%;
  	height: 200%;
    transform: rotateX(var(--grid-rotate));
    transform-origin: 100% 0 0;
  }

  &__dot {
    position:absolute;
    left: 50%;
    top: 50%;
    background:
    linear-gradient(to bottom, rgba(255,255,255,.85) 0%,rgba(255,255,255,0) 100%),
    linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,.4) 15%);
    width: var(--grid-line-y-width);
    height: 50px;
    opacity: 0;
    //box-shadow: 0 0 3px #fff;
    -webkit-backface-visibility: hidden;
    animation: gridDot 15s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);

    // Center Left
    &:nth-child(1) {
      transform: translate(calc((50% - calc(var(--grid-line-x) / 2)) - 1px),0);
      animation-delay: 0;
    }
    // Center Left
    &:nth-child(2) {
      transform: translate(calc((50% + calc(var(--grid-line-x) / 2)) - 1px),0);
      animation-delay: 3s;
    }
    // Right 1
    &:nth-child(3) {
      animation-delay: 4s;
      transform: translate(calc((50% + calc(var(--grid-line-x) / 2)) + var(--grid-line-x) - 1px),0);
    }
    // Right 2
    &:nth-child(4) {
      animation-delay: 5s;
      transform: translate(calc((50% + calc(var(--grid-line-x) / 2)) + var(--grid-line-x) + var(--grid-line-x) - 1px),0);
    }
    // Right 3
    &:nth-child(5) {
      animation-delay: 7s;
      transform: translate(calc((50% + calc(var(--grid-line-x) / 2)) + var(--grid-line-x) + var(--grid-line-x) + var(--grid-line-x) - 1px),0);
    }
    // Left 1
    &:nth-child(6) {
      animation-delay: 6s;
      transform: translate(calc((50% + calc(var(--grid-line-x) / 2)) - var(--grid-line-x) - var(--grid-line-x) - 1px),0);
    }
    // Left 2
    &:nth-child(7) {
      animation-delay: 9s;
      transform: translate(calc((50% + calc(var(--grid-line-x) / 2)) - var(--grid-line-x) - var(--grid-line-x) - var(--grid-line-x) - 1px),0);
    }
    // Left 3
    &:nth-child(8) {
      animation-delay: 8s;
      transform: translate(calc((50% + calc(var(--grid-line-x) / 2)) - var(--grid-line-x) - var(--grid-line-x) - var(--grid-line-x) - var(--grid-line-x) - 1px),0);
    }
  }
}

@keyframes play {
	0% {
		transform: rotateX(45deg) translateY(-50%);
	}
	100% {
		transform: rotateX(45deg) translateY(0);
	}
}

@keyframes gridplay {
	0% {
		transform: rotateX(45deg) translateY(-50%);
	}
	100% {
		transform: rotateX(45deg) translateY(0);
	}
}

@keyframes gridDot {
  0%   {
    top: 100%;
    opacity: 1;
  }
  90% {
    top: 0;
    opacity: .2;
  }
}
