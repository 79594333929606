.figure {
  margin: 0;

  &__wrapper {
    position: relative;
  }

  &__caption {
    position: relative;
  }

  &__caption-inner {
    text-align: left;
    color: var(--color-text-meta);
  }

  &__caption-headline {
    font-size: var(--text-sm);
    text-align: left;
    font-family: var(--font-primary);
    font-weight: var(--type-weight-meta);
    color: var(--color-text-meta);
    padding-top: 10px;
  }

  &__caption-source {
    right: 0;
    top: 0;
    position: absolute;
    transform: translate(0,-100%);

    &__button {
      text-shadow: none;
      display: block;
      position: relative;
      min-width: 40px;
      min-height: 40px;
      padding: 10px 16px;
      color: #fff;
      background-color: rgba(0,0,0,.6);
      border: 0;
      cursor: pointer;
      transition: background-color .2s ease-in-out;
    }
  }
}

.picture {
  width: 100%;
  overflow: hidden;
  display: block;

  &__image {
    display: block;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    height: auto;
    transition: filter .4s ease-in-out;

    &.lazyloading {
      filter: blur(25px);
    }

    &.lazyloaded {
      filter: blur(0);
    }

    &__actions {
      right: 0;
      top: 0;
      position: absolute;
    }

    &__button {
      text-shadow: none;
      display: block;
      position: relative;
      min-width: 40px;
      min-height: 40px;
      padding: 10px 16px;
      color: #fff;
      background-color: rgba(0,0,0,.6);
      border: 0;
      cursor: pointer;
      transition: background-color .2s ease-in-out;
    }
  }
}
