/* --------------------------------
Typography
https://medium.com/codyhouse/create-your-design-system-part-1-typography-7c630d9092bd
-------------------------------- */
::selection {
  background-color: var(--color-accent);
  color: #fff;
}

::placeholder {
  color: rgba(255,255,255,.7);
  opacity: 1;
}

body {
  font-size: var(--text-base-size);
  font-family: var(--font-primary);
  color: var(--color-text);
  line-height: var(--component-body-line-height);
  background-color: var(--color-bg);
}

a:focus,
button:focus {
  outline: none;
}

button,
input,
optgroup,
select,
textarea {
  line-height: var(--heading-line-height);
}

.headline,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: var(--type-weight-heading);
  color: var(--color-text-heading);
  margin: 0 0 var(--space-xxs);
  line-height: var(--heading-line-height);
}

/* text size */
.text--xxxl {
  font-size: var(--text-xxxl);
  line-height: var(--heading-line-height);
}

.text--xxl,
h1 {
  font-size: var(--text-xxl);
  line-height: var(--heading-line-height);
}

.text--xl,
h2 {
  font-size: var(--text-xl);
  line-height: var(--heading-line-height);
}

.text--lg,
h3 {
  font-size: var(--text-lg);
}

.text--md,
h4 {
  font-size: var(--text-md);
}

.text--sm,
h5,
small {
  font-size: var(--text-sm);
}

.text--xs,
h6 {
  font-size: var(--text-xs);
}

.text--xxs {
  font-size: var(--text-xxs);
}

p {
  margin: 0;
  //line-height: var(--body-line-height);
}

code {
  background: rgba(225, 225, 225, .15);
  padding: 0 3px;
}

mark {
  background: none;
  position: relative;
  font-weight: var(--type-weight-bold);

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -3px;
    width: calc(100% + 6px);
    height: .45em;
    color: var(--color-accent);
    background: var(--color-accent-light);
    pointer-events: none;
    z-index: -1;
  }
}

abbr[title] {
  position: relative;
  display: inline-block;
  text-decoration: none;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    border-bottom: 2px dotted;
    opacity: .4;
    transition: opacity .2s ease-in-out;
  }

  &:hover,
  &:focus {
    &:before {
      border-bottom-style: solid;
      border-bottom-color: var(--color-link);
      opacity: 1;
    }
  }
}

a {
  color: var(--color-link);
  text-decoration: none;
  font-weight: var(--type-weight-link);
  transition:
    color 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    color: var(--color-link);
  }
}

// TODO: Jan - Dieser Style bei einem generellen :focus funktioniert nicht immer. Besser wäre es, wenn via JS eine Klasse gesetzt wird.
// a {
//   &:focus {
//     outline: 2px dashed #febc39;
//   }
// }

b,
strong {
  font-weight: var(--type-weight-bold);
}

address {
  font-style: normal;
}

.is--typo-container {
  > *:first-child {
    margin-top: 0;
  }

  h1,
  h2,
  h3 {
    line-height: var(--component-heading-line-height);
    margin-bottom: calc(var(--space-sm) * var(--text-vspace-multiplier));
  }

  h4,
  h5,
  h6 {
    margin-bottom: calc(var(--space-xs) * var(--text-vspace-multiplier));
  }

  h1,
  h2,
  h3,
  h4 {
    margin-top: calc(var(--space-md) * var(--text-vspace-multiplier));
  }

  p {
    &:not(:last-child){
      margin-bottom: calc(var(--space-md) * var(--text-vspace-multiplier));
    }
  }

  ul,
  ol {
    margin: calc(var(--space-lg) * var(--text-vspace-multiplier)) 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    &:not(.list){
      list-style-position: outside;
      padding-left: 1.75em;

      li {
        &:not(:last-child){
          margin-bottom: var(--space-sm);
        }

        ol,
        ul {
          margin: var(--space-sm) 0 var(--space-lg);

          li {
            &:not(:last-child){
              margin-bottom: var(--space-sm);
            }
          }

        }
      }
    }
  }

  ul {
    &:not(.list){
      list-style: none;
      padding: 0;

      li {
        position: relative;
        padding-left: 1.25em;

        &:before {
          content: "";
          position: absolute;
          top: .5em;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          border: 3px solid var(--color-primary);
          background: transparent;
          @include mq(md) {
            top: .55em;
          }
        }
      }
    }
  }

  ol {
    list-style-type: decimal;
  }

  ol li,
  ul li,
  p {
    //line-height: var(--component-body-line-height);
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  // External links
  // [target=_blank] {
  //   position: relative;
  //   display: inline-block;
  //
  //   &:after {
  //     position: absolute;
  //     padding: 0 5px;
  //     background: #fff;
  //     left: 100%;
  //     top: 50%;
  //     content: '\eec9';
  //     font-family: var(--font-icon);
  //     display: inline-block;
  //     line-height: 1;
  //     opacity: 0;
  //     transform: scaleX(0) translateY(-50%);
  //     transform-origin: left;
  //     transition: all .2s ease;
  //   }
  //
  //   &:hover {
  //     &:after {
  //       transform: scaleX(1) translateY(-50%);
  //       opacity: 1;
  //     }
  //   }
  // }
}

/* utility classes */
.truncate {
  // truncate text if it exceeds parent
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hint-text {
  font-weight: var(--type-weight-meta);
  font-size: var(--text-xs);
  color: var(--color-text-meta);
  margin-top: var(--space-xs);

  &.is {
    &--info {
      color: var(--color-info);
    }
    &--valid {
      color: var(--color-valid);
    }
    &--warning {
      color: var(--color-warning);
    }
    &--error {
      color: var(--color-error);
    }
  }
}
