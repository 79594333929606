.is--loaded {
  .as-top {
    transform: none;
  }
}

.as-top {
  position: relative;
  width: 100%;
  height: var(--header-height);
  display: flex;
  z-index: var(--zindex-header);
  background: var(--color-bg);
  color: #fff;
  box-shadow: 0 5px 50px 0px #365761;
  transition: transform .25s ease;
  transform: translate(0, -100%);
  will-change: transform;

  &:before {
    content: '';
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: var(--header-height);
    box-shadow: 0 10px 70px 0px #357082;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  a {
    transition:
      color 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      text-shadow 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      color: var(--color-link);
      text-shadow: 0 0 10px var(--color-link);
    }
  }

  // &:hover {
  //   &:before {
  //     opacity: 1;
  //   }
  // }

  &__row {
    display: flex;
    width: 100%;
    max-width: map-get($container-max-widths, 'xl');
    margin: 0 auto;
    padding: 0 calc(#{$grid-gutter-width} / 2);
  }

  &__col {
    display: inline-flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    align-items: center;
    min-width: 0;

    &.is {
      &--align-start {
        justify-content: flex-start;
      }

      &--align-end {
        justify-content: flex-end;
      }

      &--align-center {
        justify-content: center;
      }

      &--shrink {
        flex-grow: initial;
      }
    }
  }

  &__brand {
    padding: 0;
    display: flex;
    align-items: center;

    &__link {
      display: inline-block;
    }

    // Logo sizing
    svg {
      max-height: 60px;
      width: 100%;
      max-width: 140px;
      @include mq(md) {
        max-width: 220px;
      }
    }
  }

  /*
  These styles are for headroom.js.
  Headroom hide/show the header on scroll down/up
  */
  &.as-headroom {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    will-change: transform;
    transition: transform .4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &.is {
      // when scrolling up
      &--pinned {
        transform: translateY(0%);
      }
      // when scrolling down
      &--unpinned {
        transform: translateY(-100%);
      }
      // when above offset
      &--top {

      }
      // when below offset
      &--not-top {
        &:not(.is--top){
          .is {
            &--headroom-scroll-hidden {
              display: none !important;
            }
          }
        }
      }
      // when at bottom of scoll area
      &--bottom {

      }
      // when not at bottom of scroll area
      &--not-bottom {

      }
    }
  }
}

/*
If headroom.js is activated, the header is positioned fixed and lies above the content.
With a padding-top we can compensate for that.

NOTE: Must be adapted for each project!
*/
.as-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--header-height));
  padding-top: var(--header-height);
}

// Fill space if content is too low
.as-main {
  flex: 1 1 auto;
}
