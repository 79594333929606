.pagination-outer {
  .pagination {
    display: flex;
    align-items: center;

    &__pages {
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline-flex;
      // // Overflow
      // width: 100%;
      // overflow-x: auto;
      // -webkit-overflow-scrolling: touch;

      // Hide default style
      &:before {
        display: none;
      }
    }

    &__item {
      display: none;
      position: relative;
      margin: 0 5px;
      font-size: var(--text-xs);

      &.is {
        &--shown {
          display: block;
        }
      }

    }

    &__link {
      width: 30px;
      height: 30px;
      //background: hsla(0,0%,100%,.05);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .25s ease-in-out;

      &:hover {
        background: hsla(0,0%,100%,.1);
        color: #fff;
      }

      &.is{
        &--active {
          background: var(--color-accent);
          color: #fff;
        }
      }
    }

    &__link-prev,
    &__link-next {
      position: relative;
      display: flex;
      align-items: center;
      font-size: var(--text-xs);

      .ico {
        transition: transform .25s ease-in-out;
        will-change: transform;

      }
    }

    &__link-prev {
      margin-right: var(--space-sm);

      &:hover {
        .ico {
            transform: translateX(-3px);
        }
      }
    }
    &__link-next {
      margin-left: var(--space-sm);

      &:hover {
        .ico {
            transform: translateX(3px);
        }
      }
    }
  }
}
