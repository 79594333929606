.nav-desktop {
  justify-content: flex-end;

  &.is--lvl-1,
  &.is--lvl-2,
  &.is--lvl-3 {
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }

  &__entry {
    position: relative;
    display: flex;
    align-items: center;
    font-weight: var(--type-weight-bold);
    padding: 0 var(--space-sm);
    color: var(--color-text);
    //font-size: var(--text-sm);
    text-transform: uppercase;
    height: var(--header-height);
    line-height: var(--component-heading-line-height);

    &.is {
      &--icon {
        padding: 0;
        height: 100%;
        //width: var(--space-lg);
        text-align: center;
        margin: 0 var(--space-sm);
      }
    }
  }

  &__entry-icon {
    margin-right: 15px;
    width: 45px;
    height: auto;
  }

  // level #1
  &.is--lvl-1 {
    display: flex;

    > li {
      &:not(:last-child){
        margin-right: var(--space-sm);
      }
    }
  }

  // level #2 & #3
  &.is--lvl-2,
  &.is--lvl-3 {
    // background: #09323f;
    // color: rgba(255,255,255,.7);

    > li {
      position: relative;
      
      &:not(:last-child){
        margin-bottom: 6px;
      }
    }

    .nav-desktop {
      &__entry {
        padding: var(--space-xxs) var(--space-md);
        font-size: var(--text-sm);
        white-space: nowrap;
        height: auto;
      }
    }

    &.is {
      &--offset {
        .nav-desktop__entry {
          @include mq(xl) {
            padding-left: calc(var(--space-sm) + 65px);
          }
        }
      }
    }
	}

  // Submenu and Megamenu
  &__dd {
    position: relative;

    .nav-dd {
      position: absolute;
      min-width: 100%;
      pointer-events: none;
      opacity: 0;
      transition: opacity .05s cubic-bezier(0.785, 0.155, 0.91, 0.485);
      will-change: opacity;

      background: #09323f;
      //padding: var(--space-md);
      box-shadow: 0 4px 10px -5px rgba(0,0,0,.6);

      &__content {
        width: 100%;
        text-align: left;
      }

      // Submenu position
      &.is--lvl-2 {
        left: 0;
        top: 100%;
      }

      &.is--lvl-3 {
        left: 100%;
        top: 0;
        z-index: -1;
        margin-top: calc(-1 * var(--space-md))
      }

      &.is--lvl-2,
      &.is--lvl-3 {
        padding-top: var(--space-md);
        padding-bottom: var(--space-sm);
        //transition: opacity .125s cubic-bezier(0.785, 0.155, 0.91, 0.485);
      }
    }

    // Megamenu
    &.is--megamenu {
      position: inherit;

      .nav-dd {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        max-height: 550px;

        &__content {

        }
      }
    }

    // Show submenu
    &:hover {
      > .nav-dd {
        &.is--lvl-2,
        &.is--lvl-3 {
          pointer-events: auto;
          opacity: 1;
          transition: none;
          transition-delay: 0;
        }
      }
    }
  }

  // Active class
  .is {
    &--active {
      > a {
        color: var(--color-link);
      }
    }
  }

}
