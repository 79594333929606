// Prevent italic style from <em>
[class^="ico-"],
[class*=" ico-"] {
  font-variant: normal!important;
  font-style: normal!important;
}
// Icon sizing
.ico {
  &--xxs {
    font-size: var(--text-xxs) !important;
  }
  &--xs {
    font-size: var(--text-xs) !important;
  }
  &--sm {
    font-size: var(--text-sm) !important;
  }
  &--md {
    font-size: var(--text-md) !important;
  }
  &--lg {
    font-size: var(--text-lg) !important;
  }
  &--xl {
    font-size: var(--text-xl) !important;
  }
  &--xxl {
    font-size: var(--text-xxl) !important;
  }
  &--xxxl {
    font-size: var(--text-xxxl) !important;
  }
}
