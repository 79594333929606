.hamburger {
  position: relative;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: 0;
  display: flex;
  align-items: center;
  font-size: var(--text-xxs);
  opacity: .7;
  letter-spacing: 0.042em;
  text-transform: uppercase;
  font-weight: var(--type-weight-bold);
  transition: opacity 0.2s ease-out;
  will-change: opacity;
  color: #fff;

  &__text {
    color: inherit;
    margin-right: 15px;
    transition:
      all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &__dots {
    position: relative;
    width: 16px;
    height: 16px;

    span {
      display: inline-block;
      position: absolute;
      width: 3px;
      height: 3px;
      background: #fff;
      border-radius: 100%;
      transition:
        all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    span {
      &:nth-of-type(1) {
        top: 0;
        left: 0;
      }
      &:nth-of-type(2) {
        top: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
      &:nth-of-type(3) {
        top: 0;
        right: 0;
      }
      &:nth-of-type(4) {
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
      }
      &:nth-of-type(5) {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
      &:nth-of-type(6) {
        top: 50%;
        right: 0;
        transform: translate3d(0, -50%, 0);
      }
      &:nth-of-type(7) {
        bottom: 0;
        left: 0;
      }
      &:nth-of-type(8) {
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%, 0, 0);

      }
      &:nth-of-type(9) {
        bottom: 0;
        right: 0;
      }
    }
  }

  &:hover:not([aria-expanded="false"]),
  &[aria-expanded="true"] {
    opacity: 1;
    color: var(--color-accent);
    text-shadow: 0 0 10px var(--color-link);

    .hamburger {
      &__dots {
        span {
          background: var(--color-accent);

          &:nth-of-type(1) {
            animation: dot-hover-1 0.5s ease-in-out infinite alternate;
          }
          &:nth-of-type(3) {
            animation: dot-hover-2 0.5s ease-in-out infinite alternate;
          }
          &:nth-of-type(7) {
            animation: dot-hover-3 0.5s ease-in-out infinite alternate;
          }
          &:nth-of-type(9) {
            animation: dot-hover-4 0.5s ease-in-out infinite alternate;
          }
        }
      }
    }
  }
}

@keyframes dot-hover-1 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-2px, -2px);
  }
}
@keyframes dot-hover-2 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(2px, -2px);
  }
}
@keyframes dot-hover-3 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(-2px, 2px);
  }
}
@keyframes dot-hover-4 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(2px, 2px);
  }
}
