.nav-meta {
  &__navbar {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: rgba(255,255,255,0.7);
    font-size: var(--text-xxs);
    font-weight: var(--type-weight-bold);
    letter-spacing: .042em;
  }

  &__navbar-link {
    color: rgba(255,255,255,0.7);
    font-size: inherit;

    &:not(:last-child){
      margin-right: var(--space-md);
      @include mq(xl) {
        margin-right: var(--space-lg);
      }
    }
  }
}
