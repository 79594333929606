.breadcrumb-outer {
  .breadcrumb {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    // Overflow
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &__item {
      position: relative;
      display: inline-flex;
      font-size: var(--text-sm);

      &:not(:last-child){
        margin-right: 8px;

        &:after {
          content: '\ee34';
          display: flex;
          align-items: center;
          font-family: var(--font-icon);
          font-size: var(--text-xxs);
          color: var(--color-text-meta);
          line-height: normal;
          margin-left: 8px;
        }
      }
    }

    &__link {
      display: inline-flex;
      align-items: center;

    }

    &__text {
      font-weight: var(--type-weight-link);
      flex-shrink: 0;
      max-width: 90px;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mq(xl) {
        max-width: 180px;
      }
    }

    &.is {
      &--muted {
        a {
          color: var(--color-text-meta);

          &:hover,
          &:focus {
            color: var(--color-link);
          }
        }
      }
    }
  }

}
