/*
Everything in the <MAIN> tag is divided into <SECTIONS>. This is the basic structure of a content page.

Similar to the grid, the section is equivalent to the container class. Sections can be subdivided into other <ROW>'s.
*/

.section {
  position: relative;

  &__row {
    position: relative;
    padding: var(--space-xl) 0;
    @include mq(md) {
      padding: var(--space-xxl) 0;
    }
    // prevent margin collapsing
    // overflow: auto; // collides with seperator
    .row {
      .row:last-child {
        .section__col:last-child {
          margin-bottom: 0;
        }
      }
    }

    /*
    Section divider need extra space
    */
    &.is {
      &--offset-top {
        padding-top: 90px;
        margin-top: 90px;
        @include mq(lg){
          padding-top: 180px;
          margin-top: 180px;
        }
      }
      &--offset-bottom {
        padding-bottom: 90px;
        margin-bottom: 90px;
        @include mq(lg){
          padding-bottom: 180px;
          margin-bottom: 180px;
        }
      }
    }
  }

  // &__col {
  //   margin-bottom: var(--space-lg);
  //   @include mq(md) {
  //     margin-bottom: var(--space-xl);
  //   }
  // }

  &__front {

  }

  /*
  Section seperators
  Could be svg or images
  */
  &__seperator {
    pointer-events: none;
    width: 300%; // Auch, wenn es SVG's sind oder erhalten diese 100% ??
    position: absolute;
    z-index: -1;
    background-color: transparent;
    @include mq(md){
      width: 100%;
    }

    &__image {
      width: 100%; // For 100% SVG's width
      max-width: 100%;
      height: auto;
    }

    &.is {
      &--top {
        top: -1px;
        bottom: auto;
        left: 50%;
        transform: translate(-50%,-50%);
      }

      &--bottom {
        top: auto;
        bottom: -1px;
        left: 50%;
        transform: translate(-50%,50%);
      }
    }
  }

  /*
  Content Background-Image: This wrapper is behind all the other content!
  */
  &__bg {
    position: absolute;
    pointer-events: none;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 250px;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    opacity: 0.3;
    @include mq(lg) {
      min-height: 400px;
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(-180deg, rgba(3, 32, 45, 0) 0%, var(--color-bg) 80%);
    }
  }

  &__content {
    position: relative;
    //z-index: 1;
  }
}

// Modifiers
.is {
  // Spacing
  &--offset {
    &-top {
      padding-top: var(--space-xl);
    }
    &-right {
      @include mq(lg) {
      padding-right: var(--space-xl);
      }
    }
    &-bottom {
      padding-bottom: var(--space-xl);
    }
    &-left {
      @include mq(lg) {
        padding-left: var(--space-xl);
      }
    }
  }
  // Add inner top & bottom shadow
  &--inner-shadow {
    &:before,
    &:after {
      position: absolute;
      display: block;
      width: 100%;
      height: 50px;
      z-index: 1;
      content: '';
      pointer-events: none;
      left: 0;
    }

    &:before {
      top: 0;
      background: linear-gradient(to bottom,rgba(26,26,26,.08) 0,rgba(26,26,26,0) 100%);
    }
  }

  // Background colors
  // TODO: Daraus eine SASS Mixin machen?
  &--bg-color-1 {
    background-color: var(--color-bg-light);

    > path {
      fill: var(--color-bg-light);
    }
  }

  &--bg-color-2 {
    background-color: var(--color-bg-lighter);

    > path {
      fill: var(--color-bg-lighter);
    }
  }

  &--bg-color-3 {
    background-color: var(--color-bg-dark);

    > path {
      fill: var(--color-bg-dark);
    }
  }

  &--bg-color-4 {
    background-color: #fff;

    > path {
      fill: #fff;
    }
  }

  &--bg-color-5 {
    background-color: var(--gray-10);

    > path {
      fill: var(--gray-10);
    }
  }

  &--bg-color-6 {
    background-color: var(--color-primary);

    > path {
      fill: var(--color-primary);
    }
  }

  &--bg-color-7 {
    background-color: var(--color-accent);

    > path {
      fill: var(--color-accent);
    }
  }

  &--bg-color-8 {
    background-color: var(--color-accent-light);

    > path {
      fill: var(--color-accent-light);
    }
  }

  // Seperator
  // Obsolet ?!
  &--separator {
    position: relative;

    .svg-seperator {
      pointer-events: none;
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      background-color: transparent;

      &.is {
        &--top {
          top: -1px;
          bottom: auto;
        }

        &--bottom {
          top: auto;
          bottom: -1px;
          transform: translate(-50%, 0) scaleY(-1);
        }
      }
    }
  }

  &--md-container {
    position: relative;
    max-width: var(--container-md-max-widths);
    margin-left: auto;
    margin-right: auto;
  }
  &--sm-container {
    position: relative;
    max-width: var(--container-sm-max-widths);
    margin-left: auto;
    margin-right: auto;
  }
  &--xs-container {
    position: relative;
    max-width: var(--container-xs-max-widths);
    margin-left: auto;
    margin-right: auto;
  }
}
