.footer {
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    box-shadow: 0 -5px 70px -20px #357082;
  }

  a {
    transition:
      color 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      text-shadow 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      color: var(--color-link);
      text-shadow: 0 0 10px var(--color-link);
    }
  }

  &__inner {
    padding: var(--space-md) 0;
    @include mq(lg) {
      display: flex;
      padding: var(--space-xl) 0;
    }
  }

  &__logo {
    display: block;
    flex-shrink: 0;

    @include mq(lg) {
      margin-right: var(--space-xl);
    }

    svg {
      max-height: 60px;
      width: 100%;
      max-width: 140px;
      @include mq(md) {
        max-width: 220px;
      }

      path {
        fill: #fff;
      }
    }
  }

  &__sitemap {
    margin: var(--space-lg) 0;
    @include mq(sm) {
      margin: var(--space-lg) 0;
      column-count: 2;
      column-gap: var(--space-xl);
    }
    @include mq(lg) {
      margin: 0 var(--space-xl) 0 0;
    }

    a {
      font-size: var(--text-sm);
      letter-spacing: .042em;
      text-transform: uppercase;
      font-style: normal;
      font-weight: var(--type-weight-bold);
      margin: 0 0 1em;
      display: block;
      color: var(--color-text-meta);
      @include mq(lg) {
        font-size: var(--text-xs);
      }
    }
  }

  &__address {
    font-size: var(--text-sm);
    letter-spacing: .042em;
    text-transform: uppercase;
    font-style: normal;
    font-weight: var(--type-weight-bold);
    margin: 0 0 var(--space-xl);
    display: block;
    color: var(--color-text-meta);
    @include mq(lg) {
      flex-grow: 1;
      font-size: var(--text-xs);
      margin-bottom: 0;
    }
  }

  &__address-text {
    color: #fff;
    margin: var(--space-sm) 0 0;
  }

  &__logo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;

    @include mq(md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 40px;
    }

    @include mq(lg) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
      gap: 80px;
    }

    &__box {
      // background-color: white;
      padding: 12px;
      display:flex;
      align-items: center; 
      justify-content:center;

      @include mq(md) {
        padding: 6px;
      }
  

      img {
        display: block;
        width: 100%;
        max-height: 80px; 
        filter: brightness(0) invert(1);

        &.filter-saturate {
          filter: saturate(0)
        }
      }
    }
  }

  &__partnerlogo-wrapper{
    display: flex;
  }

  &__tuev {
    filter: grayscale(1);
    margin-bottom: 40px;

    @include mq(md) {
      margin-bottom: 0;
    }

  }

  &__socials {
    margin: 0 0 0 30px;

    &__links {
      display: flex;
    }

  }
}
