@mixin dot {
  content: '';
  background-color: var(--color-primary);
  display: block;
  height: 7px;
  width: 7px;
  border-radius: 50%;
}

.hr {
  border: 0;
  width: 100%;
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
  @include mq(md) {
    margin-top: var(--space-xxl);
    margin-bottom: var(--space-xxl);
  }

  &.is {
    // spacing
    &--sm {
      margin-top: var(--space-sm);
      margin-bottom: var(--space-sm);
    }
    &--md {
      margin-top: var(--space-md);
      margin-bottom: var(--space-md);
    }
    &--lg {
      margin-top: var(--space-lg);
      margin-bottom: var(--space-lg);
    }
    // basic horizontal line
    &--style-1 {
      border-bottom: 1px solid var(--color-border);
    }

    // three centered dots
    &--style-2 {
      overflow: visible;
      position: relative;
      @include dot();

      &:before {
        @include dot();
        position: absolute;
        left: -32px;
      }
      &:after {
        @include dot();
        position: absolute;
        left: 32px;
      }
    }

    // outfaded gradient line
    &--style-3 {
      height: 1px;
      background-image: linear-gradient(to right, var(--gray-1), var(--color-primary), var(--color-accent), var(--gray-1));
    }

    &--list {
      border-bottom: 2px solid var(--color-border);
      margin: 0;
    }
  }
}
