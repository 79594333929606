//
// Sizing mixins with margin and padding
//
// This mixin loop will create "margin helpers" and "padding helpers" for use in your web projects.
// It will generate several classes such as:
//
// .mr-10 gives margin-right
// .mt-15 gives margin-top
// .m-15 gives margin in all directions
// .pb-5 gives padding-bottom
// .p-40 gives padding in all directions
//
// The first letter is "m" or "p" for MARGIN or PADDING
// Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
// Third letter is the number of spacing in px.
// Adjust the amounts generated by editing the $spaceamounts variable below.
//

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125, 130, 135, 140, 145, 150);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {
  @each $side in $sides {

    @if $side == all {
      .m-#{$space} {
        margin: #{$space}px!important;
      }

      .p-#{$space} {
        padding: #{$space}px!important;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px!important;
      }

      .p#{str-slice($side, 0, 1)}-#{$space} {
        padding-#{$side}: #{$space}px!important
      }
    }
  }
}
