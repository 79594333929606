// Note
#gbpr {
  .klaro {
    .cookie-notice {
      .cn-body {
        position: relative;
        background: var(--color-bg);
        border: 2px solid var(--color-border);
        border-radius: 5px;
        overflow: visible;

        &:before {
          content: '';
          pointer-events: none;
          position: absolute;
          border-radius: inherit;
          top: -2px;
          left: -2px;
          right: -2px;
          bottom: -2px;
          z-index: 0;
          border: 2px solid var(--color-accent);
          box-shadow: 0 3px 20px var(--color-accent);
          opacity: 1;
          transition: opacity .6s cubic-bezier(0.0, 0.0, 0.2, 1);
        }

        .cn-text {
          #klaro-notice-title {
            @extend .headline;
          }
        }

        .cn-ok {
          background: var(--color-bg-lighter);
          border-top: none;

          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          @include mq(md){
            flex-wrap: inherit;
            justify-content: inherit;
          }

          .cn-grow {
            flex: 1 0 100%;
            order: 3;
            justify-content: center;
            margin-top: 20px;
            @include mq(md){
              flex: 1 0 auto;
              order: inherit;
              justify-content: inherit;
              margin-top: 0;
            }
          }

          .cbtn {
            @extend .btn;
            @extend .btn--link;
          }
          .cbtn.cbtn-outline {
            margin-right: 20px;
            @extend .btn;
            @extend .btn--outline;
          }
          .cbtn.cbtn-primary {
            @extend .btn;
            @extend .btn--primary;
          }
        }
      }
    }
  }
}

// Modal Settings
.klaro.klaro-modal-portal {
  .cookie-modal {
    .cm-modal {
      position: relative;
      background: var(--color-bg);
      border: 2px solid var(--color-border);
      border-radius: 5px;
      overflow: visible;
      color: inherit;

      &:before {
        content: '';
        pointer-events: none;
        position: absolute;
        border-radius: inherit;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        z-index: 0;
        border: 2px solid var(--color-accent);
        box-shadow: 0 3px 20px var(--color-accent);
        opacity: 1;
        transition: opacity .6s cubic-bezier(0.0, 0.0, 0.2, 1);
      }

      p {
        color: inherit;
      }

      .cm-header {
        border-color: var(--color-bg-lighter);
        #klaro-modal-title {
          @extend .headline;
        }
      }

      .cm-body {
        .cm-apps {
          color: inherit;

          .cm-app {
            color: inherit;
          }
        }
      }

      .cm-footer {
        display: flex;
        justify-content: flex-end;
        background: var(--color-bg-lighter);
        border-top: none;

        .cbtn.cbtn-primary {
          @extend .btn;
          @extend .btn--primary;
        }
      }
    }
  }
}


.klaro .cookie-modal .cm-app-input:checked+.cm-app-label .slider,
.klaro .cookie-notice .cm-app-input:checked+.cm-app-label .slider {
  background-color: var(--color-primary)!important;
}

.klaro .cm-app-title {
  color: var(--color-primary)!important;
  font-weight: var(--type-weight-bold)!important;
  font-size: var(--text-md)!important;
  text-transform: uppercase!important;
  display: block;margin-bottom: 5px!important;
}

.klaro .cookie-modal .cm-opt-out,
.klaro .cookie-modal .cm-required {
  font-size: var(--text-md) !important;
  padding-left: 10px !important;
}

.klaro .cookie-modal .purposes {
  font-size: var(--text-sm)!important;
  margin-top: 5px!important;
}
