.contacts-box {
  @include mq(md) {
    display: flex;
  }

  &__form,
  &__info {
    @include mq(md) {
      flex: 1 1 0;
    }
  }

  &__form {
    @include mq(md) {
      flex-grow: 2;
    }
  }
}
