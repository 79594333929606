.contacts-outer {
  margin: var(--space-lg) 0;
  // @include mq(lg) {
  //   display: flex;
  //   justify-content: space-between;
  // }
}

.contacts__block {
  // margin: 0 auto;

  &:not(:last-child){
    margin-bottom: var(--space-md);
  }

  &:nth-child(1){
    @include mq(lg) {
      max-width: var(--container-xs-max-widths);
    }
  }
}

.contact {
  text-align: center;

  &:hover {
    .contact__media {
      &:before {
        opacity: 1;
      }
    }
  }

  &__media {
    position: relative;
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
    border-radius: 9999px;
    background: #fff;
    width: 120px;
    height: 120px;
    background: #fff;

    &:before {
      content: "";
      pointer-events: none;
      position: absolute;
      border-radius: inherit;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      z-index: 0;
      border: 2px solid var(--color-accent);
      box-shadow: 0 3px 20px var(--color-accent);
      opacity: 0;
      transition: opacity .6s cubic-bezier(0,0,.2,1);
    }
  }

  &__image {
    max-width: 100%;
    width: 120px;
    height: 120px;
    border-radius: 9999px;
    overflow: hidden;
  }

  &__name,
  &__position {
    //font-size: var(--text-sm);
  }

  &__name {
    color: var(--color-primary);
    font-weight: var(--type-weight-bold);
  }

  &__position {
    color: var(--color-text-meta);
    font-size: var(--text-xs);
  }

  &__links {
    margin-top: var(--space-md);
  }

  &__link {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: center;
    // border: 2px solid #415961;
    // border-radius: 999px;
    margin: 0 var(--space-xs);
    // width: 40px;
    // height: 40px;
    //text-transform: uppercase;
    color: hsla(0,0%,100%,.7);
    font-size: var(--text-xxs);
    font-weight: var(--type-weight-bold);
    letter-spacing: .042em;

    &:not(:last-child){
      margin-bottom: var(--space-sm);
    }
  }

  .btn-group {
    align-items: center;
  }
}
