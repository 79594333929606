.is {
  /* Sizing */
  /* The first letter is capitalized */
  &--first-letter {
    > p:first-of-type::first-letter {
      font-size: 200%;
      font-family: var(--font-secondary);
      letter-spacing: .0652em;
      line-height: 1em;
    }
  }
  &--multicol {
    @include mq(md) {
      column-count: 2;
      column-gap: 80px;
      break-inside: avoid;
    }
  }
}
