.as-drawer {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  top: var(--header-height);
  width: 100vw;
  height: calc((calc(var(--vh, 1vh) * 100)) - var(--header-height));
  overflow: hidden;
  pointer-events: none;
  z-index: var(--zindex-drawer);

  a {
    transition:
      color 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      text-shadow 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      color: var(--color-link);
      text-shadow: 0 0 10px var(--color-link);
    }
  }

  // Backdropp
  // TODO: Styles für den backdrop via @extend laden. Somit würde der style dafür nur 1x definiert werden!
  &__backdrop {
    content: '';
    display: block;
    position: absolute;
    z-index: var(--zindex-backdrop);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    pointer-events: none;
    opacity: 0;
    transition: opacity .25s cubic-bezier(.4,0,.2,1);
    will-change: opacity;
  }

  &__layer {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-color: var(--color-bg);
    position: relative;
    width: 100%;
    max-width: var(--mobile-nav-width);
    height: 100%;
    z-index: var(--zindex-drawer);
    pointer-events: auto;
    transform: translateX(103%);
    will-change: transform;
    overflow-x: hidden;
  }

  &__block {
    position: relative;
    background: var(--color-bg);
    width: 100%;
    padding: var(--space-sm) var(--space-md);

    &.is {
      &--sticky-top {
        position: sticky;
        top: 0;
      }

      &--border-top {
        border-top: 1px solid var(--color-border);
      }
    }
  }

  &__subheader {
    padding: var(--space-md) var(--space-md) 0;
    text-transform: uppercase;
    color: var(--color-text-subtle);
    font-weight: var(--type-weight-meta);
    font-size: var(--text-sm);
  }

  /*
  Modifiers
  */
  &--visible {
    .as-drawer {
      // Show Backdrop
      &__backdrop {
        opacity: 1;
        pointer-events: auto;
      }
      // Show layer
      &__layer {
        // TODO: Das !important anders lösen!
        transform: none!important;
      }
    }
  }

  &--animating {
    .as-drawer {
      &__layer {
        // Layer fade-out
        // Hiding the sidebar is a bit faster than fading in
        transition: all 0.25s cubic-bezier(.4,0,.2,1);
      }
    }
  }

  &--animating {
    &.as-drawer {
      &--visible {
        .as-drawer {
          &__layer {
            // Layer fade-in
            transition: all 0.25s cubic-bezier(.4,0,.2,1);
          }
        }
      }
    }
  }
}
