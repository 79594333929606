.csv-rings {
  overflow: visible;
  position: relative;
  z-index: -2;
  width: 100vw;
  height: 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%,0%);
  @include mq(lg) {
    width: 1000px;
    height: 1000px;
  }
}

.csv-signet {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110px;
  height: auto;

  z-index: -1;
  transform: translate(-50%,-50%);
}

.cvs-ring {
  overflow: visible;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 150vw; // oversize
  transform: translate(-50%,-50%);
  @include mq(lg) {
    width: 100%;
    height: 100%;
  }


  &:nth-child(1) {
    z-index: -4;
  }

  &:nth-child(2) {
    z-index: -3;
  }

  &:nth-child(3) {
    z-index: -2;
  }
  &:nth-child(4) {
    z-index: -1;
  }
}

.cloud-server {
  overflow: visible;

  // &:before {
  //   content: '';
  //   width: 150%;
  //   height: 150%;
  //   position: absolute;
  //   left: -50%;
  //   top: -50%;
  //   transform: translate(25%,25%);
  //   z-index: 10;
  //   border-radius: 9999px;
  //
  //   background: radial-gradient(#e66465, rgba(0,0,0,0));
  // }

  &__img {
    max-width: 100%;
    // @include mq(md) {
    //   max-width: 1200px;
    // }
    @include mq(lg) {
      max-width: inherit;
    }
  }
}

.spectrum {
  position: relative;
  width: 80vw;
  height: 80vw;
  @include mq(md) {
    width: 40vw;
    height: 40vw;
    max-width: 540px;
    max-height: 540px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -50%;
    top: -50%;
    z-index: 10;
    width: 200%;
    height: 200%;
    background: linear-gradient(to bottom, rgba(3,32,45,1) 40%,rgba(255,255,255,0) 100%);
    @include mq(lg) {
      background: linear-gradient(270deg, rgba(3,32,45,1) 50%,rgba(255,255,255,0) 100%);
    }
  }

  &__ring {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    opacity: .2;
    border: 2px solid rgba(0,0,0,.6);
    border-radius: 9999px;
    box-shadow:
      inset 0 0 4vw 0 rgba(0, 0, 0, 1);

    animation: shrink 8s cubic-bezier(0.4, 0.26, 0, 0.97);
    animation-iteration-count: infinite;
    animation-direction: alternate;
    will-change: transform;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 9999px;
      opacity: .2;
      box-shadow:
        0 0 2vw 0 var(--color-accent);
      animation: fade 4s cubic-bezier(0.4, 0.26, 0, 0.97);
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }

    &:nth-of-type(1) {
      width: 115%;
      height: 115%;
    }

    &:nth-of-type(2) {
      width: 135%;
      height: 135%;
      animation-delay: .3s;

      &:after {
        animation-delay: .2s;
      }
    }

    &:nth-of-type(3) {
      width: 160%;
      height: 160%;
      animation-delay: .6s;

      &:after {
        animation-delay: .4s;
      }
    }

    @keyframes shrink {
    	0% {
        opacity: .2;
    		transform: translate(-50%,-50%) scale(.94);
    	}
    	100% {
        opacity: 1;
    		transform:  translate(-50%,-50%) scale(1);
    	}
    }

    @keyframes fade {
    	0% {
    		opacity: .2;
    	}
    	100% {
        opacity: .8;
    	}
    }
  }
}

.sphere {
  position: relative;
  width: 100vw;
  height: 100vw;
  @include mq(md) {
    width: 40vw;
    height: 40vw;
    max-width: 840px;
    max-height: 840px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -50%;
    top: -50%;
    z-index: 10;
    width: 200%;
    height: 200%;
    background: linear-gradient(270deg, rgba(3,32,45,1) 50%,rgba(255,255,255,0) 100%);
  }


  &__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    border-radius: 46%; // looks more organic
    //background: rgba(138, 43, 226, 0.02);

    &:nth-of-type(1) {
      width: 100%;
      height: 103%;
      animation: rt 40s infinite linear;
      box-shadow: 0 0 1px 0 rgb(0,190,225),
                  inset 0 0 10px 0 rgb(0,190,225);
    }

    &:nth-of-type(2) {
      width: 103%;
      height: 106%;
      animation: rt 50s infinite linear;
      box-shadow: 0 0 1px 0 var(--color-primary),
                  inset 0 0 10px 0 var(--color-primary);
    }

    &:nth-of-type(3) {
      width: 106%;
      height: 109%;
      width: 109%;
      height: 106%;
      animation: rt 60s infinite linear;
      box-shadow: 0 0 1px 0 rgb(6,101,144),
                  inset 0 0 10px 0 rgb(6,101,144);
    }

    &:nth-of-type(4) {
      width: 109%;
      height: 112%;
      animation: rt 70s infinite linear;
      box-shadow: 0 0 1px 0 var(--color-accent),
                  inset 0 0 10px 0 var(--color-accent);
    }

    &:nth-of-type(5) {
      width: 109%;
      height: 112%;
      animation: rt 70s infinite linear;
      box-shadow: 0 0 100px 0 var(--color-accent),
                  inset 0 0 20px 0 var(--color-accent);
    }
  }
}

@keyframes rt {
  100% {
    transform: translate(-50%,-50%) rotate(360deg);
  }
}

.full-height {
  position: relative;
  min-height: calc((calc(var(--vh, 1vh) * 100)) - var(--header-height));
  //max-height: 700px;
  @include mq(md) {
    min-height: auto;
    display: flex;
    align-items: center;
  }

  &.is {
    &--md--full-height-75 {
      @include mq(md) {
        min-height: 75vh;
      }
    }
  }
}


.is {
  &--offset-hero {
    @include mq(md) {
      padding-top: 90px;
      padding-bottom: 50px;
    }
  }
}

.platform {
  position: absolute;
  top: 0;
  left: 0;

  pointer-events: none;
  text-align: center;
  z-index: -2; // -1
  margin: 0 auto;

  &.is {
    /*
    Obsolete?
    Set a single image on top of the background media (grid). The image ratio is 3:2 and 600x400 px!
    */
    &--front-image {
      position: relative;
      top: auto;
      left: auto;
      margin-top: 30px;

      @include mq(md) {
        position: absolute;
        width: 50%;
        top: 50%;
        left: 75%;
        transform: translate(-50%,-50%);
        margin-top: 0;
      }

      .platform {
        &__item-media {
          max-width: 100%;
          height: auto;
          // prevent oversizing
          max-height: 280px;
        }

      }
    }
    /*
    Single element on grid
    */
    &--front-media {
      position: relative;
      top: auto;
      left: auto;
      margin-top: 30px;
      @include mq(md) {
        position: absolute;
        width: 50%;
        top: 50%;
        left: 75%;
        transform: translate(-50%,-50%);
        margin-top: 0;
      }

      &.is {
        &--overflow-media {

        }
      }
      .platform {
        &__item {
          position: relative;
          max-width: 80%;
          margin: 0 auto;
        }

        // Obsolet
        &__item-media {
          max-width: 100%;
          height: auto;
          // prevent oversizing
          max-height: 50vh;
        }

        &__item-image {
          max-width: 100%;
          height: auto;
          // prevent oversizing
          max-height: 50vh;
          z-index: -1;
          // box-shadow: 0 0 100px 10px rgb(78, 169, 239);
          // border-radius: 9999px;
          /*
          Das Bild steht für sich alleine ohne HTMl Element.
          Erst auf'n Desktop muss es abolsut positioniert werden!
          */
          @include mq(md) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }

          /*
          Unter dem Bild liegt noch ein HTML Element, weshalb das
          Bild hier auch MOBIL absolut positioniert werden muss.
          */
          &.is {
            &--stacked {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              max-width: 140px;
            }
          }
        }

      }
    }
    /*
    Background
    */
    &--background-media {
      width: 100%;
      z-index: -3;

      .platform {
        &__item {
          &:before {
            content: '';
            pointer-events: none;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            background: linear-gradient(to top, #03202d 10%, transparent 40%);
          }
        }

        &__item-media {
          position: relative;
          display: block;
          left: 50%;
          transform: translate(-50%,0);
          max-width: 100vh;
          @include mq(md) {
            max-width: none;
          }
        }
      }
    }
    /*
    Set google maps behind the page hgroup
    */
    &--maps {
      z-index: -2;
      top: 0;
      left: 0;
      width: 100%;
      // 100px for offsetting next section
      height: calc(100% + 100px);

      .platform {
        &__item {
          width: 100%;
          height: 100%;
          //border-radius: 25%;
          overflow: hidden;

          &:before {
            content: '';
            position: absolute;
            pointer-events: none;
            top: -1px;
            left: -1px;
            bottom: -1px;
            right: -1px;
            background:
              linear-gradient(to right, rgba(3,32,45,1) 0%,rgba(3,32,45,1) 10%,rgba(0,0,0,0) 50%),
              linear-gradient(to left, rgba(3,32,45,1) 0%,rgba(3,32,45,1) 10%,rgba(0,0,0,0) 50%);
            box-shadow:
              inset 0 -70px 100px 10px rgb(3, 32, 45),
              inset 0 -70px 100px 10px rgb(3, 32, 45);
          }
        }
      }
    }
    /*
    Positioning whole platform
    */
    &--right-center {
      top: 50%;
      right: 25%;

      &.is--centered {
        transform: translate(50%,-50%);
      }
    }

    &--center {
      top: 50%;
      left: 50%;

      &.is--centered {
        transform: translate(-50%,-50%);
      }
    }

  }
}

// .platform {
//   // position: relative;
//   // display: flex;
//   // align-items: center;
//
//   &__media-group {
//     position: relative;
//   }
//
//   &__media-item {
//     position: absolute;
//     z-index: 0;
//     pointer-events: none;
//     //max-width: 100%; // Shrink enabled!
//
//     // Specific
//     &.is {
//       &--graphic-hero {
//         bottom: 0;
//         left: 50%;
//         transform: translate(-50%, 0);
//         width: 120%;
//
//         @include mq(md) {
//           bottom: inherit;
//           top: 50%;
//           left: auto;
//           right: 0;
//           width: auto;
//           transform: translate(0,-50%);
//         }
//       }
//
//       &--grid {
//         bottom: 0;
//         left: 50%;
//         transform: translate(-50%,20%);
//       }
//     }
//     // Global
//     &.is {
//       &--full-width {
//         width: 100%;
//       }
//       // Default
//       &--left {
//         top: 50%;
//         left: 0;
//
//         &.is--centered {
//           transform: translate(-50%,-50%);
//         }
//       }
//       &--center {
//         top: 50%;
//         left: 50%;
//
//         &.is--centered {
//           transform: translate(-50%,-50%);
//         }
//       }
//       &--right {
//         top: 50%;
//         right: 0;
//
//         &.is--centered {
//           transform: translate(50%,-50%);
//         }
//       }
//       // Top
//       &--top-left {
//         top: 0;
//         left: 0;
//
//         &.is--centered {
//           transform: translate(-50%,-50%);
//         }
//       }
//       &--top-middle {
//         top: 0;
//         left: 50%;
//         transform: translate(-50%);
//
//         &.is--centered {
//           transform: translate(-50%,-50%);
//         }
//       }
//       &--top-right {
//         top: 0;
//         right: 0;
//
//         &.is--centered {
//           transform: translate(50%,-50%);
//         }
//       }
//       // Bottom
//       &--bottom-left {
//         bottom: 0;
//         left: 0;
//
//         &.is--centered {
//           transform: translate(-50%,50%);
//         }
//       }
//       &--bottom-middle {
//         bottom: 0;
//         left: 50%;
//         transform: translate(50%);
//
//         &.is--centered {
//           transform: translate(-50%,50%);
//         }
//       }
//       &--bottom-right {
//         bottom: 0;
//         right: 0;
//
//         &.is--centered {
//           transform: translate(50%,50%);
//         }
//       }
//       // Center Right side
//       &--right-center {
//         top: 50%;
//         right: 25%;
//
//         &.is--centered {
//           transform: translate(50%,-50%);
//         }
//       }
//       // Center Left side
//       &--left-center {
//         top: 50%;
//         left: 25%;
//
//         &.is--centered {
//           transform: translate(-50%,-50%);
//         }
//       }
//     }
//   }
//
//   &__media-img {
//     max-width: 100%;
//   }
// }
