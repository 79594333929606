.nav-mobile {
  width: 100%;

  a {
    transition:
      color 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
      text-shadow 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:hover {
      color: var(--color-link);
      text-shadow: 0 0 10px var(--color-link);
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: var(--color-bg);
    width: 100%;
    overflow: hidden;

    &.is {
      &--primary {
        .nav-mobile {
          &__link {
            font-size: var(--text-sm);
            font-weight: var(--type-weight-bold);
            text-transform: uppercase;
          }
        }
      }
      &--secondary {
        .nav-mobile {
          &__item {
            border: none;
          }

          &__link {
            font-size: var(--text-xs);
            color: var(--color-text-meta);
          }
        }
      }

      &--primary,
      &--secondary {
        .nav-mobile {
          &__link {
          &.is--back {
              font-size: var(--text-xxs);
              color: var(--color-text-meta);
            }
          }
        }
      }
    }
  }


  &__item {
    display: flex;
    align-items: center;
    flex: 0 0 100%;

    &:not(:last-child){
      border-bottom: 1px solid var(--color-border-light);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: var(--text-sm);
    color: var(--color-primary);
    font-weight: var(--type-weight-bold);
    padding: var(--space-lg) var(--space-md);
    letter-spacing: .042em;
    //background-color: var(--gray-1);

    .nav-mobile__icon.is--leading {
      width: 26px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: var(--text-xxs);
    font-weight: var(--type-weight-bold);
    color: var(--color-text);
    letter-spacing: .042em;
    padding: var(--space-md) 0 var(--space-unit) var(--space-md);
    margin-right: var(--space-xl);
  }

  &__icon {
    align-self: center;
    font-size: var(--text-xs);

    &.is {
      &--leading {
        margin-right: 16px;
        width: 32px;
        height: auto;
      }

      &--trailing {
        padding: var(--space-md);
      }

      &--sm {
        font-size: var(--text-sm);
      }
    }
  }

  &__note {
    font-size: var(--text-xs);
    color: var(--color-accent);
  }

  &__hint {
    font-size: var(--text-xs);
    color: var(--color-text-meta);
  }
}

// Modifiers
.nav-mobile {
  .is {
    /*
    Styles for both navigation variants: cover and collapse
    */
    &--submenu {

    }

    /*
    Nav variant: Cover

    It has multi-level functionality, allowing endless
    nesting of navigation elements.
    */
    &--cover {
      position: fixed;
      right: 0;
      bottom: 0;
      overflow-y: auto;
      pointer-events: none;
      opacity: 0;
      transform: translateX(410px);
      transition: transform .25s ease, opacity .25s ease;
      z-index: 2;
      max-width: 400px;
      height: 100%;
      height: calc((calc(var(--vh, 1vh) * 100)) - var(--header-height));
    }

    /*
    Nav variant: Collapse
    */
    &--collapse {
      position: relative;
      z-index: 1;
      width: 100%;
      font-size: var(--text-sm);
      padding-left: var(--space-md);
      max-height: 0;
      overflow: hidden;

      .nav-mobile {
        &__item {
          flex-wrap: wrap;
          border: none;
        }
      }
    }

    // Show current nav level
    &--visible {
      pointer-events: all;
      opacity: 1;
      transform: none;
      max-height: 100%;
      max-height: 100vh;
    }
  }
}
