/* Default button style */
.btn {
  position: relative;
  text-decoration: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  text-align: inherit;
  //color: var(--color-link);
  line-height: inherit;
  background: none;
  transition: all .2s ease;

  &:active {
    transition: none;
    outline: none;
  }

  &:hover {
    color: var(--color-link);
    cursor: pointer;
  }

  &:focus:not(:active) {
    // transition: none;
    // outline: 2px dashed #febc39;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: var(--btn-disabled-bg);
    color: var(--btn-disabled-label);
    box-shadow: none;
    opacity: 0.6;

    &:visited {
      color: var(--btn-disabled-label);
    }
  }
}

/* Themes */
.btn {
  &--link,
  &--primary,
  &--outline {
    display: flex;
    align-items: center;
    justify-content: center;
    //white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .042rem;
    min-width: 120px;
    max-width: 260px;
    font-size: var(--text-xs);
    font-weight: var(--type-weight-bold);
    padding: var(--space-sm) var(--space-md);
    @include mq(md) {
      display: inline-flex;
    }
    // Icons
    .ico {
      color: inherit;
      flex-shrink: 0;
      font-size: var(--text-md);
      margin-right: 12px;

      &--end {
        margin-right: 0;
        margin-left: 12px;
      }
    }

    &.is {
      &--auto-width {
        min-width: auto;
      }

      &--btn-sm {
        min-width: 120px;
        max-width: 180px!important;
        font-size: var(--text-xxs);
        padding: var(--space-sm) var(--space-sm);
      }
    }
  }

  // &--primary,
  // &--outline {
  //   &:after {
  //     content: '\edb2';
  //     font-family: var(--font-icon);
  //     width: 10px;
  //     height: 16px;
  //     position: absolute;
  //     right: 15px;
  //     top: 50%;
  //     margin-top: 1px;
  //     transform: translate(100px,-50%);
  //     transition: transform .4s cubic-bezier(.27,.1,.25,.97);
  //   }
  //
  //   &:hover {
  //     background-color: var(--color-primary);
  //     color: #fff;
  //
  //     &:after {
  //       transform: translate(0,-50%);
  //     }
  //   }
  // }

  &--link {
    border-radius: 999px;
    min-width: auto;
    padding: 0;
    color: var(--color-link);
    transition: all .3s ease;

    &:hover {
      //background-color: var(--color-link);
      //text-shadow: 0 0 10px var(--color-link);
      animation: textblitz .3s ease-in-out forwards;
    }

    &.is {
      &--inverse {
        color: rgba(0,0,0,.8);
      }
    }
  }

  &--primary {
    position: relative;
    border-radius: 999px;
    background-color: var(--btn-primary-bg);
    color: var(--btn-primary-label);
    transition: all .3s ease;

    &:before {
      content: '';
      position: absolute;
      border-radius: 500px;
      top: 0;
      left: 0;
      z-index: 0;
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 0 var(--btn-primary-bg);
    }

    &:hover {
      background-color: var(--color-link);
      text-shadow: 0 0 10px var(--color-link);
      animation: textblitz .3s ease-in-out forwards;

      &:before {
        animation: shadowOutFade .6s ease-in-out forwards;
      }
    }

    &.is {
      &--inverse {
        color: #fff;
        background-color: var(--btn-primary-hover);

        &:active {
          background-color: var(--btn-primary-active);
        }
      }
    }
  }

  &--outline {
    transition: all .3s ease;
    border-radius: 500px;
    color: #fff;
    background-color: #09323F;

    &:before {
      content: '';
      position: absolute;
      border-radius: 500px;
      top: 0;
      left: 0;
      z-index: 0;
      display: block;
      width: 100%;
      height: 100%;
      border: 2px solid var(--color-border);
    }

    &:hover {
      color: var(--color-link);
      text-shadow: 0 0 10px var(--color-link);

      &:before {
        animation: blitz .3s ease-in-out forwards;
      }
    }

    &.is {
      &--inverse {
        color: rgba(0,0,0,.8);
        background-color: transparent;

        &:before {
          border-color: #c1ced2;
        }

        &:hover {
          background-color: transparent;
          color: var(--color-link);

          &:before {
            border-color: var(--color-accent);
          }
        }

        &:active {
          background-color: var(--btn-secundary-inverse-active);

          &:before {
            border-color: var(--btn-secundary-inverse-active);
          }
        }
      }
    }
  }

  &--icon {
    justify-content: center;
    align-items: center;
    display: inline-flex;
    border-radius: 50%;
    width: var(--space-md);
    height: var(--space-md);
    color: var(--color-text);

    &:hover {
      text-shadow: 0 0 10px var(--color-link);
      animation: textblitz .3s ease-in-out forwards;
    }

    .ico {
      // Prevent js modal click bug
      pointer-events: none;
    }

  }

  &--cta {
    position: fixed;
    z-index: 10;
    right: 0;
    bottom: 100px;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background: #fff;
    font-size: 8px;
    font-weight: var(--type-weight-bold);
    text-transform: uppercase;
    letter-spacing: .042em;
    //color: var(--color-accent);
    padding: var(--space-sm);
    background-color: var(--color-link);
    text-shadow: 0 0 10px var(--color-link);
    color: #fff;
    box-shadow:
      0 0 10px -8px rgba(0,0,0,.8),
      0 0 20px -15px rgba(0,0,0,.25);
    transition: all .3s ease;
    @include mq(md) {
      font-size: 10px;
      bottom: auto;
      top: 50%;
      right: 0;
      width: 70px;
      height: 70px;
      padding: var(--space-md);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      transform: translate(0,-50%);
    }
    .ico {
      font-size: 22px;
      margin-bottom: 5px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 0 var(--btn-primary-bg);
    }

    &:hover {
      background-color: var(--color-link);
      text-shadow: 0 0 10px var(--color-link);
      animation: textblitz .3s ease-in-out forwards;

      &:before {
        animation: shadowOutFade .6s ease-in-out forwards;
      }
    }

    &:active {
      transition: none;
      outline: none;
    }

  }
}

/*
Theme
*/
.btn-group {
  margin-top: var(--space-lg);

  &.is {
    // Mobil vertical, desktop horizontal
    &--auto {
      display: flex;
      flex-wrap: wrap;

      > * {
        margin-bottom: var(--space-md);

        &:not(:last-child){
          margin-right: var(--space-md);
        }
      }
    }

    // Always horizontel
    &--inline {
      display: inline-flex;

      > * {
        min-width: auto;

        &:not(:last-child){
          margin-right: var(--space-md);
        }
      }
    }
    // Always vertical
    &--vertical {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-md);

      > * {
        width: 220px;

        &:not(:last-child){
          margin-bottom: var(--space-md);
        }
      }
    }
  }
  // display: inline-flex;
  // flex-wrap: wrap;
  //
  // > * {
  //   margin-right: var(--space-md);
  //
  //   &:last-of-type {
  //     margin-right: 0;
  //   }
  // }
  //
  // &.is {
  //   &--btn-block {
  //     display: inline-block;
  //
  //     > * {
  //       display: block;
  //       margin: 0;
  //
  //       &:not(:last-child){
  //         margin-bottom: var(--space-md);
  //
  //       }
  //     }
  //
  //   }
  // }
}

@keyframes textblitz {
  0% {
    color: var(--btn-primary-label);
  }
  25% {
    color: #fff
  }
  50% {
    color: var(--btn-primary-label);
  }
  100% {
    color: #fff;
  }
}

@keyframes shadowOutFade {
  0% {
    box-shadow: 0 0 0 0 var(--btn-primary-bg);
    opacity: 1;
  }
  25% {
    box-shadow: 0 0 20px 0 var(--btn-primary-bg);
    opacity: .75;
  }
  100% {
    box-shadow: 0 0 40px 0 var(--btn-primary-bg);
    opacity: 0;
  }
}

@keyframes blitz {
  0% {
    border-color: #fff;
  }
  25% {
    border-color: var(--color-border);
  }
  50% {
    border-color: #fff;
  }
  100% {
    border-color: var(--color-accent);
  }
}
