/*
Basic styles
*/
.hgroup-outer {
  display: flex;
  align-items: center;

  .hgroup {
    &__label {
      text-transform: uppercase;
      font-weight: var(--type-weight-bold);
      color: var(--color-text-meta);
      line-height: var(--component-heading-line-height);
      letter-spacing: .032em;
    }

    &__headline {
      letter-spacing: .042rem;

      a {
        color: inherit;
      }
    }

    &__sub-headline {
      line-height: var(--component-body-line-height);
      color: var(--color-text-subtle);
    }

    &__icon {
      width: 60px;
      margin-right: var(--space-md);
    }
  }

  /*
  Modifiers
  */
  &.is {
    /*
    Mostly used as a section headline
    */
    &--primary {
      padding-bottom: calc(var(--space-lg) * var(--text-vspace-multiplier));
      //padding-top: calc(var(--space-xl) * var(--text-vspace-multiplier));

      .hgroup {
        &__label {
          position: relative;
          margin-bottom: calc(var(--space-xxs) * var(--text-vspace-multiplier));
          font-size: var(--text-sm);

//           &:after {
//             content: '';
//             display: block;
//             height: 4px;
//             width: 40px;
//             margin: var(--space-sm) auto;
//             background-color: var(--color-accent);
//             @include mq(md) {
//               margin: var(--space-sm) 0;
//             }
//           }
        }

        &__headline {
          font-size: var(--text-xxxl);
          margin: 0;
        }

        &__sub-headline {
          margin-top: calc(var(--space-xs) * var(--text-vspace-multiplier));
          //font-size: var(--text-md);
        }
      }
    }

    /*
    For headlines that are not in the flow text and whose margins are not needed.
    In addition, everything is a little smaller.
    */
    &--secundary {
      padding-bottom: calc(var(--space-md) * var(--text-vspace-multiplier));
      padding-top: calc(var(--space-sm) * var(--text-vspace-multiplier));

      .hgroup {
        &__label {
          font-size: var(--text-xs);
          letter-spacing: .04em;
          //margin-bottom: calc(var(--space-xs) * var(--text-vspace-multiplier));
        }

        &__headline {
          margin: 0;
        }

        &__sub-headline {
          margin-top: calc(var(--space-sm) * var(--text-vspace-multiplier));
        }
      }
    }

  }
}
